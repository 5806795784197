<template>
  <el-container class="h-100">
    <meta name="viewport" content="width=device-width,user-scalable=no,initial-scale=1.0">
    <div class="h-100 w-500">
      <header class="h5_header">
        <h1>{{ h5Data.doc_header.doc_title }}</h1>
        <p v-if="h5Data.doc_header.hasOwnProperty('doc_subtitle')">{{ h5Data.doc_header.doc_subtitle }}</p>
      </header>
      <div class="h5_nav mar-t-15">
        <a @click="handleClick('gs')" class="active">综述</a>
        <a @click="handleClick('jczl')">信息走势</a>
        <a @click="handleClick('zdxxzy')">信息分布</a>
        <a @click="handleClick('xxylb')">信息一览表</a>
      </div>
      <div v-if="activeIndex == 'gs'" class="gs_cont">
        <div class="h5_card">
          <h3>本次简报收集了：</h3>
          <p>{{ h5Data.diagram_info1_header }}</p>
        </div>
        <div class="h5_card mar-t-15">
          <h3>信息焦点</h3>
          <p>{{ h5Data.diagram_info2_1 }}</p>
          <ul class="zdxxzy_cont">
            <li v-for="(row, index) in h5Data.diagram_info2_1_1" :key="index">
              <h4 @click="openLink(row.source_url)">
                <var>{{ index + 1 }}.</var>
                <span class="mg" v-if="row.emotion < 50">敏感</span>
                <span class="fmg" v-else>非敏感</span>
                  {{ row.title }}
              </h4>
              <p class="fbsj">
                <span>发布时间：{{ row.published }}</span>
                <span>媒体：{{ row.category }}</span><br>
                <span>来源：{{ row.category === '微博' || row.category === '微信' ? row.author : row.source_name }}</span>
              </p>
            </li>
          </ul>
          <table class="hot_keywords">
            <tr>
              <th>热门关键词</th>
              <th>相关条数</th>
              <th>上升关键词</th>
              <th>相关条数</th>
            </tr>
            <tr v-for="(row, index) in h5Data.diagram_info2_1_2" :key="index">
              <td>{{ row.word }}</td>
              <td>{{ row.count }}</td>
              <td v-if="h5Data.diagram_info2_1_3[index]">{{ h5Data.diagram_info2_1_3[index].word }}</td>
              <td v-if="h5Data.diagram_info2_1_3[index]">{{ h5Data.diagram_info2_1_3[index].count }}</td>
            </tr>
          </table>
          <div class="hr-10"></div>
        </div>
      </div>
      <div v-if="activeIndex == 'jczl'" class="jczl_cont">
        <div class="h5_card">
          <h3>（一）信息声量走势</h3>
          <p style="margin-bottom: 10px;">{{ h5Data.diagram_info2_2 }}</p>
          <div class="h5_card mar-t-15">
            <h2 class="tit"><span>信息声量走势</span></h2>
            <div class="map" id="voicetrend">
            </div>
          </div>
          <ul class="zdxxzy_cont">
            <li v-for="(row, index) in h5Data.diagram_info2_2_2" :key="index">
              <h4 @click="openLink(row.source_url)">
                <var>{{ index + 1 }}.</var>
                <span class="mg" v-if="row.emotion < 50">敏感</span>
                <span class="fmg" v-else>非敏感</span>
                  {{ row.title }}
              </h4>
              <p class="fbsj">
                <span>发布时间：{{ row.published }}</span>
                <span>媒体：{{ row.category }}</span><br>
                <span>来源：{{ row.category === '微博' || row.category === '微信' ? row.author : row.source_name }}</span>
              </p>
            </li>
          </ul>
        </div>
        <div class="h5_card mar-t-15">
          <h3>（二）敏感信息走势</h3>
          <p>{{ h5Data.diagram_info2_3 }}</p>
          <div class="h5_card mar-t-15">
            <h2 class="tit"><span>情感声量走势</span></h2>
            <div class="map" id="emotiontrend">
            </div>
          </div>
          <ul class="zdxxzy_cont">
            <li v-for="(row, index) in h5Data.diagram_info2_3_2" :key="index">
              <h4 @click="openLink(row.source_url)">
                <var>{{ index + 1 }}.</var>
                <span class="mg" v-if="row.emotion < 50">敏感</span>
                <span class="fmg" v-else>非敏感</span>
                  {{ row.title }}
              </h4>
              <p class="fbsj">
                <span>发布时间：{{ row.published }}</span>
                <span>媒体：{{ row.category }}</span><br>
                <span>来源：{{ row.category === '微博' || row.category === '微信' ? row.author : row.source_name }}</span>
              </p>
            </li>
          </ul>
          <div class="ov mar-t-15 h5_card">
            <h2 class="tit"><span>敏感信息占比</span></h2>
            <div class="map" id="sensitive">
            </div>
          </div>
          <table class="hot_keywords mg_hot_keywords mar-t-15">
            <tr>
              <th><span class="red">敏感</span>信息热门关键词</th>
              <th>相关条数</th>
            </tr>
            <tr v-for="(row, index) in h5Data.diagram_info2_3_4" :key="index">
              <td>{{ row.word }}</td>
              <td>{{ row.count }}</td>
            </tr>
          </table>
          <div class="echarts_empty" v-show="h5Data.diagram_info2_3_4.length < 1">
            <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
          </div>
          <div class="hr-10"></div>
        </div>
      </div>
      <div v-if="activeIndex == 'zdxxzy'" class="zdxxzy_cont">
        <div class="h5_card">
          <h3>信息分布</h3>
          <p>{{ h5Data.diagram_info2_4 }}</p>
          <div class="h5_card mar-t-15">
            <h2 class="tit"><span>媒体类型占比图</span></h2>
            <div class="map" id="mediaSourceMap" v-show="h5Data['diagram_info2_4_1'].length >= 1">
            </div>
            <div class="echarts_empty" v-show="h5Data['diagram_info2_4_1'].length < 1">
              <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
            </div>
          </div>
          <div class="mar-t-15">
            <div class="h5_card">
              <h2 class="tit">
                <span v-if="h5Data.hasOwnProperty('diagram_info2_4_catalog')">{{ h5Data.diagram_info2_4_catalog.diagram_info2_4_2 }}</span>主要媒体来源
              </h2>
              <div class="w-100 mar-t-15 hide">
                <ul class="nav nav-pills">
                  <li><a>网媒</a></li>
                  <li class="active"><a>微博</a></li>
                  <li><a>微信</a></li>
                  <li><a>论坛</a></li>
                  <li class="fz1rem"> 敏感</li>
                  <li><img src="@/assets/images/common/switch_on.png" alt="" width="30"></li>
                </ul>
              </div>
              <div class="tab-content mtly" v-show="h5Data['diagram_info2_4_2'].length >= 1">
                <ul>
                  <li>
                    <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span
                            class="redu">占比</span></h5>
                  </li>
                  <li v-for="(row, index) in h5Data.diagram_info2_4_2" :key="index">
                    <span class="num" v-if="index === 0">1</span>
                    <span class="num bg_ec7" v-if="index === 1">2</span>
                    <span class="num bg_ffb" v-if="index === 2">3</span>
                    <span class="num bg_2a6" v-if="index >= 3">{{ index + 1 }}</span>
                    <a>{{ row.name }}</a>
                    <div class="progressBar">
                      <dl class="barbox">
                        <dd class="barline">
                          <div class="charts" :style="row.style"></div>
                        </dd>
                      </dl>
                    </div>
                    <span class="redu">{{ row.count }}</span>
                    <span class="redu">{{ row.percent }}%</span>
                  </li>
                </ul>
              </div>
              <div class="echarts_empty" v-show="h5Data['diagram_info2_4_2'].length < 1">
                <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
              </div>
            </div>
            <div class="h5_card mar-t-15">
              <h2 class="tit">
                <span v-if="h5Data.hasOwnProperty('diagram_info2_4_catalog')">
                    {{ h5Data.diagram_info2_4_catalog.diagram_info2_4_3 }}
                </span>
                主要媒体来源
              </h2>
              <div class="w-100 mar-t-15 hide">
                <ul class="nav nav-pills">
                  <li class="active"><a>网媒</a></li>
                  <li><a>微博</a></li>
                  <li><a>微信</a></li>
                  <li><a>论坛</a></li>
                  <li class="fz1rem"> 敏感</li>
                  <li><img src="@/assets/images/common/switch_on.png" alt="" width="30"></li>
                </ul>
              </div>
              <div class="tab-content mtly" v-show="h5Data['diagram_info2_4_3'].length >= 1">
                <ul>
                  <li>
                    <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                  </li>
                  <li v-for="(row, index) in h5Data.diagram_info2_4_3" :key="index">
                    <span class="num" v-if="index === 0">1</span>
                    <span class="num bg_ec7" v-if="index === 1">2</span>
                    <span class="num bg_ffb" v-if="index === 2">3</span>
                    <span class="num bg_2a6" v-if="index >= 3">{{ index + 1 }}</span>
                    <a>{{ row.name }}</a>
                    <div class="progressBar">
                      <dl class="barbox">
                        <dd class="barline">
                          <div class="charts" :style="row.style"></div>
                        </dd>
                      </dl>
                    </div>
                    <span class="redu">{{ row.count }}</span>
                    <span class="redu">{{ row.percent }}%</span>
                  </li>
                </ul>
              </div>
              <div class="echarts_empty" v-show="h5Data['diagram_info2_4_3'].length < 1">
                <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
              </div>
            </div>
            <div class="h5_card mar-t-15">
              <h2 class="tit">
                <span v-if="h5Data.hasOwnProperty('diagram_info2_4_catalog')">
                    {{ h5Data.diagram_info2_4_catalog.diagram_info2_4_4 }}
                </span>
                主要媒体来源
              </h2>
              <div class="w-100 mar-t-15 hide">
                <ul class="nav nav-pills">
                  <li><a>网媒</a></li>
                  <li><a>微博</a></li>
                  <li class="active"><a>微信</a></li>
                  <li><a>论坛</a></li>
                  <li class="fz1rem"> 敏感</li>
                  <li><img src="@/assets/images/common/switch_on.png" alt="" width="30"></li>
                </ul>
              </div>
              <div class="tab-content mtly" v-show="h5Data['diagram_info2_4_4'].length >= 1">
                <ul>
                  <li>
                    <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                  </li>
                  <li v-for="(row, index) in h5Data.diagram_info2_4_4" :key="index">
                    <span class="num" v-if="index === 0">1</span>
                    <span class="num bg_ec7" v-if="index === 1">2</span>
                    <span class="num bg_ffb" v-if="index === 2">3</span>
                    <span class="num bg_2a6" v-if="index >= 3">{{ index + 1 }}</span>
                    <a>{{ row.name }}</a>
                    <div class="progressBar">
                      <dl class="barbox">
                        <dd class="barline">
                          <div class="charts" :style="row.style"></div>
                        </dd>
                      </dl>
                    </div>
                    <span class="redu">{{ row.count }}</span>
                    <span class="redu">{{ row.percent }}%</span>
                  </li>
                </ul>
              </div>
              <div class="echarts_empty" v-show="h5Data['diagram_info2_4_4'].length < 1">
                <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
              </div>
            </div>
          </div>
          <div class="h5_card mar-t-15">
            <h2 class="tit"><span><em>敏感</em>信息媒体类型占比图</span></h2>
            <div class="map" id="negativeSourceMap" v-show="h5Data['diagram_info2_4_5'].length >= 1">
            </div>
            <div class="echarts_empty" v-show="h5Data['diagram_info2_4_5'].length < 1">
              <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
            </div>
          </div>
          <div class="h5_card mar-t-15">
            <h2 class="tit">
              <span v-if="h5Data.hasOwnProperty('diagram_info2_4_catalog')">{{ h5Data.diagram_info2_4_catalog.diagram_info2_4_6 }}</span>主要媒体来源
            </h2>
            <div class="w-100 mar-t-15 hide">
              <ul class="nav nav-pills">
                <li><a>网媒</a></li>
                <li class="active"><a>微博</a></li>
                <li><a>微信</a></li>
                <li><a>论坛</a></li>
                <li class="fz1rem red"> 敏感</li>
                <li><img src="@/assets/images/common/switch_close.png" alt="" width="30"></li>
              </ul>
            </div>
            <div class="tab-content mtly" v-show="h5Data['diagram_info2_4_6'].length >= 1">
              <ul>
                <li>
                  <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                </li>
                <li v-for="(row, index) in h5Data.diagram_info2_4_6" :key="index">
                  <span class="num" v-if="index === 0">1</span>
                  <span class="num bg_ec7" v-if="index === 1">2</span>
                  <span class="num bg_ffb" v-if="index === 2">3</span>
                  <span class="num bg_2a6" v-if="index >= 3">{{ index + 1 }}</span>
                  <a>{{ row.name }}</a>
                  <div class="progressBar">
                    <dl class="barbox">
                      <dd class="barline">
                        <div class="charts_red" :style="row.style"></div>
                      </dd>
                    </dl>
                  </div>
                  <span class="redu">{{ row.count }}</span>
                  <span class="redu">{{ row.percent }}%</span>
                </li>
              </ul>
            </div>
            <div class="echarts_empty" v-show="h5Data['diagram_info2_4_6'].length < 1">
              <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
            </div>
          </div>
          <div class="h5_card mar-t-15">
            <h2 class="tit">
              <span v-if="h5Data.hasOwnProperty('diagram_info2_4_catalog')">{{ h5Data.diagram_info2_4_catalog.diagram_info2_4_7 }}</span>主要媒体来源
            </h2>
            <div class="w-100 mar-t-15 hide">
              <ul class="nav nav-pills">
                <li class="active"><a>网媒</a></li>
                <li><a>微博</a></li>
                <li><a>微信</a></li>
                <li><a>论坛</a></li>
                <li class="fz1rem red"> 敏感</li>
                <li><img src="@/assets/images/common/switch_close.png" alt="" width="30"></li>
              </ul>
            </div>
            <div class="tab-content mtly" v-show="h5Data['diagram_info2_4_7'].length >= 1">
              <ul>
                <li>
                  <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span
                          class="redu">占比</span></h5>
                </li>
                <li v-for="(row, index) in h5Data.diagram_info2_4_7" :key="index">
                  <span class="num" v-if="index === 0">1</span>
                  <span class="num bg_ec7" v-if="index === 1">2</span>
                  <span class="num bg_ffb" v-if="index === 2">3</span>
                  <span class="num bg_2a6" v-if="index >= 3">{{ index + 1 }}</span>
                  <a>{{ row.name }}</a>
                  <div class="progressBar">
                    <dl class="barbox">
                      <dd class="barline">
                        <div class="charts_red" :style="row.style"></div>
                      </dd>
                    </dl>
                  </div>
                  <span class="redu">{{ row.count }}</span>
                  <span class="redu">{{ row.percent }}%</span>
                </li>
              </ul>
            </div>
            <div class="echarts_empty" v-show="h5Data['diagram_info2_4_7'].length < 1">
              <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
            </div>
          </div>
          <div class="h5_card mar-t-15">
            <h2 class="tit">
              <span v-if="h5Data.hasOwnProperty('diagram_info2_4_catalog')">{{ h5Data.diagram_info2_4_catalog.diagram_info2_4_8 }}</span>主要媒体来源
            </h2>
            <div class="w-100 mar-t-15 hide">
              <ul class="nav nav-pills">
                <li><a>网媒</a></li>
                <li><a>微博</a></li>
                <li class="active"><a>微信</a></li>
                <li><a>论坛</a></li>
                <li class="fz1rem red"> 敏感</li>
                <li><img src="@/assets/images/common/switch_close.png" alt="" width="30"></li>
              </ul>
            </div>
            <div class="tab-content mtly" v-show="h5Data['diagram_info2_4_8'].length >= 1">
              <ul>
                <li>
                  <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span
                          class="redu">占比</span></h5>
                </li>
                <li v-for="(row, index) in h5Data.diagram_info2_4_8" :key="index">
                  <span class="num" v-if="index === 0">1</span>
                  <span class="num bg_ec7" v-if="index === 1">2</span>
                  <span class="num bg_ffb" v-if="index === 2">3</span>
                  <span class="num bg_2a6" v-if="index >= 3">{{ index + 1 }}</span>
                  <a>{{ row.name }}</a>
                  <div class="progressBar">
                    <dl class="barbox">
                      <dd class="barline">
                        <div class="charts_red" :style="row.style"></div>
                      </dd>
                    </dl>
                  </div>
                  <span class="redu">{{ row.count }}</span>
                  <span class="redu">{{ row.percent }}%</span>
                </li>
              </ul>
            </div>
            <div class="echarts_empty" v-show="h5Data['diagram_info2_4_8'].length < 1">
              <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeIndex == 'xxylb'" class="zdxxzy_cont">
        <div class="h5_card" v-for="(item, index) in list.data" :key="index" style="margin-bottom: 10px;">
          <h4>
            <var>{{ index + 1 }}.</var>
            <span class="mg" v-if="item.emotion == 'negative'">敏感</span>
            <span class="fmg" v-else>非敏感</span>
              {{ item.title }}
          </h4>
          <p class="fbsj">
            <span>发布时间：{{ item.published }}</span>
            <span>媒体：{{ item.category }}</span><br>
            <span>来源：{{ (item.category == "微博" || item.category == "微信") ? item.author : item.site }}</span>
          </p>
          <p class="summary" v-if="item.description && item.category !== '微博'">摘要：{{ item.description }}</p>
          <a :href="item.source_url" target="_blank" class="more">查看原文 &gt;&gt;</a>
        </div>
        <div class="t-c xh button_group">
          <el-button type="primary" v-loading="list.loading" @click="moreList()" v-if="list.hasMore">查看更多</el-button>
          <el-button type="primary" v-else>暂无更多</el-button>
        </div>
      </div>
      <footer class="h5_footer" v-if="version === 'common'">数知舆情管理系统 版权所有</footer>
    </div>
  </el-container>
</template>
<script>
  import {items as briefingItems, detail} from "@/api/briefing";
  import {globalCreateChart, globalGetEcharts, globalMediaColor} from "@/utils/helpers";
  export default {
    name: "component-brief-advanced",
    components: {},
    data() {
      return {
        activeIndex: "gs",
        h5Data: {},
        list: {data: [], hasMore: true, page: 0, loading: false},
        identical: false,
        voicetrendChart: null,
        version: process.env.VUE_APP_VERSION
      }
    },
    mounted() {
      this.loadData();
    },
    watch: {
      activeIndex: function (index) {
        if (index === 'zdxxzy') {
          if (this.voicetrendChart) {
            this.voicetrendChart.dispose()
          }
        }
      }
    },
    methods: {
      loadData() {
        const {id} = this.$route.query;
        if (!id) {
          this.$message.error('不存在该简报');
          return false;
        }
        detail({id: id}).then(res => {
          const {state, data} = res.data;
          if (state) {
            this.h5Data = JSON.parse(data.data);
            this.handleData();
          } else {
            this.$message.error(res.data.error);
          }
        }).catch(err => {
          window.console.error(err)
        })
      },
      moreList() {
        let id = this.$route.query.id
        let page = this.list.page + 1
        let params = {id: id, page: page}
        this.list.loading = true
        briefingItems(params).then(res => {
          if (res.data.state) {
            let result = res.data.data
            if (result.size == 0) {
              this.list.hasMore = false
            } else {
              this.list.data = this.list.data.concat(result.data)
              this.list.page = result.page
            }
            this.list.loading = false
          } else {
            this.$message.error(res.data.error)
          }
        }).catch(res => {
          this.$message.error("加载列表失败，服务错误")
        })
      },
      handleData() {
        var data = this.h5Data;
        _(data.diagram_info2_4_2).forEach((item, i) => {
          data.diagram_info2_4_2[i].style = "width:" + data.diagram_info2_4_2[i].percent + "%;"
        })
        _(data.diagram_info2_4_3).forEach((item, i) => {
          data.diagram_info2_4_3[i].style = "width:" + data.diagram_info2_4_3[i].percent + "%;"
        })
        _(data.diagram_info2_4_4).forEach((item, i) => {
          data.diagram_info2_4_4[i].style = "width:" + data.diagram_info2_4_4[i].percent + "%;"
        })
        _(data.diagram_info2_4_6).forEach((item, i) => {
          data.diagram_info2_4_6[i].style = "width:" + data.diagram_info2_4_6[i].percent + "%;"
        })
        _(data.diagram_info2_4_7).forEach((item, i) => {
          data.diagram_info2_4_7[i].style = "width:" + data.diagram_info2_4_7[i].percent + "%;"
        })
        _(data.diagram_info2_4_8).forEach((item, i) => {
          data.diagram_info2_4_8[i].style = "width:" + data.diagram_info2_4_8[i].percent + "%;"
        })
        this.h5Data = data
      },
      makeMap() {
        var _this = this
        _this.$nextTick(() => {
          _this.voicetrend()
          _this.emotiontrend()
          _this.sensitive()
        })
      },
      voicetrend() {
        var _this = this
        let element = window.document.getElementById('voicetrend')
        let option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            top: "10px",
            icon: "circle",
            itemWidth:10,
            itemHeight:10,
            data: _this.h5Data.diagram_info2_2_1.medias,
            type: 'scroll',
            itemGap:10,
            pageIconColor:"#555DFE"
          },
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100
            }
          ],
          grid: {
            left: '38px',
            right: '62px',
            top: '40px',
            bottom: '20px',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: _this.h5Data.diagram_info2_2_1.keys
          },
          yAxis: {
            type: 'value'
          },
          series: _this.h5Data.diagram_info2_2_1.data.map((item) => {
            item.color = globalMediaColor(item.name);
            return item;
          })
        }

        var myChart = globalCreateChart(element, option)
        this.voicetrendChart = myChart
//        this.$nextTick(() =>{
//          var parent = window.document.getElementById("voicetrend")
//          var child = parent.getElementsByTagName("div")
//
//          window.jQuery(child).css({display: "block"})
//        })
      },
      emotiontrend() {
        var _this = this
        var element = window.document.getElementById('emotiontrend')
        var echarts = globalGetEcharts()
        _.each(this.h5Data.diagram_info2_3_1.data, (item) => {
          if (item.name === '敏感') {
            _this.h5Data.diagram_info2_3_1.negative = item.data
          } else if (item.name === '非敏感') {
            _this.h5Data.diagram_info2_3_1.positive = item.data
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            data: ['敏感', '非敏感'],
            top: "10px",
            itemWidth:10,
            itemHeight:10,
            itemGap:20,
            icon: "circle"
          },
          grid: {
            left: '38px',
            right: '62px',
            top: '40px',
            bottom: '20px',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: _this.h5Data.diagram_info2_3_1.keys
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100
            }
          ],
          color:['#FC5D73','#555DFE'],
          series: [
            {
              name: '敏感',
              type: 'line',
//             stack: '总量',
              data: _this.h5Data.diagram_info2_3_1.negative,
              smooth: true,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#FC5D73'
                  }, {
                    offset: 1,
                    color: '#fff'
                  }])
                }
              },
            },
            {
              name: '非敏感',
              type: 'line',
//             stack: '总量',
              data: _this.h5Data.diagram_info2_3_1.positive,
              smooth: true,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#555DFE'
                  }, {
                    offset: 1,
                    color: '#fff'
                  }])
                }
              },
            }
          ]
        }
        globalCreateChart(element, option)
      },
      sensitive() {
        var _this = this
        let element = window.document.getElementById('sensitive')
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: ({d}%)"
          },
          legend: {
            // orient: 'vertical',
            // x: 'left',
            data: ['敏感', '非敏感'],
            top: "10px",
            icon: "circle",
            itemWidth:10,
            itemHeight:10,
            formatter: function (name) {
              let total = _this.h5Data.diagram_info2_3_3.negative_count + _this.h5Data.diagram_info2_3_3.positive_count
              let neg = (_this.h5Data.diagram_info2_3_3.negative_count / total * 100).toFixed(2).toString()
              let pos = (_this.h5Data.diagram_info2_3_3.positive_count / total * 100).toFixed(2).toString()
              return name == "敏感" ? "敏感 " + neg + "%" : "非敏感 " + pos + "%"
            }
          },
          color: ['#FC5D73','#555DFE'],
          series: [
            {
              name: '情感分布',
              type: 'pie',
              center: ['50%', '60%'],
              radius: ['45%', '60%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '30',
                    fontWeight: 'bold'
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: [{name: "敏感", value: _this.h5Data.diagram_info2_3_3.negative_count}, {
                name: "非敏感",
                value: _this.h5Data.diagram_info2_3_3.positive_count
              }]
            }
          ]
        }
        globalCreateChart(element, option)
      },
      source() {
        var data = this.h5Data.diagram_info2_4_1
        var kMaps = {}
        var total = 0
        var keys = []
        data.map(function (item) {
          total += item.count
        })
        data = data.map(function (item) {
          var rate = (item.count / total) * 100
          rate = rate.toFixed(2).toString()
          kMaps[item.name] = rate + "% "
          keys.push(item.name)
          item.itemStyle = {color: globalMediaColor(item.name)}
          item.value = item.count
          return item
        })
        var option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          legend: {
            orient: 'vertical',
            left: '10px',
            bottom: "20px",
            data: keys,
            itemWidth:10,
            itemHeight:10,
            itemGap:5,
            icon: "circle",
            formatter: function (name) {
              return name + " " + kMaps[name]
            }
          },
          series: [
            {
              name: '来源分布',
              type: 'pie',
              radius: ['45%', '60%'],
              center: ['60%', '50%'],
              data: data,
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
                normal: {
                  label: {
                    show: false
                  },
                  labelLine: {
                    show: false
                  }
                }
              }
            }
          ]
        };
        this.$nextTick(function () {
          var element = window.document.getElementById('mediaSourceMap');
          if (!element) return false
          globalCreateChart(element, option)
        })
      },
      negativeSource() {
        var data = this.h5Data.diagram_info2_4_5
        var kMaps = {}
        var total = 0
        var keys = []
        data.map(function (item) {
          total += item.count
        })
        data = data.map(function (item) {
          var rate = (item.count / total) * 100
          rate = rate.toFixed(2).toString()
          kMaps[item.name] = rate + "% "
          keys.push(item.name)
          item.itemStyle = {color: globalMediaColor(item.name)}
          item.value = item.count
          return item
        })
        var option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          legend: {
            orient: 'vertical',
            left: '10px',
            bottom: "20px",
            data: keys,
            itemWidth:10,
            itemHeight:10,
            itemGap:5,
            icon: "circle",
            formatter: function (name) {
              return name + " " + kMaps[name]
            }
          },
          series: [
            {
              name: '来源分布',
              type: 'pie',
              radius: ['45%', '60%'],
              center: ['50%', '50%'],
              data: data,
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
                normal: {
                  label: {
                    show: false
                  },
                  labelLine: {
                    show: false
                  }
                }
              }
            }
          ]
        };
        this.$nextTick(function () {
          var element = window.document.getElementById('negativeSourceMap');
          if (!element) return false
          globalCreateChart(element, option)
        })
      },
      handleClick(index) {
        this.activeIndex = index
        let indexMap = ["gs", "jczl", "zdxxzy", "xxylb"]
        _(indexMap).forEach((v, k) => {
          window.jQuery(".h5_nav a").removeClass("active")
          if (v == index) {
            window.jQuery(`.h5_nav a:eq(${k})`).addClass("active")
            return false
          }
        })
        switch (index) {
          case "jczl":
            this.makeMap()
            break
          case "zdxxzy":
            this.source()
            this.negativeSource()
            break;
          case "xxylb":
            if (!this.list.page && !this.list.data.length) this.moreList()
            break
          case "gs":
            this.$nextTick(() => {
              var parent = window.document.getElementById("h5-parent")
              if (parent) {
                var child = parent.getElementsByTagName("div")
                window.jQuery(child).css({display: "none"})
              }
            })
            break
        }
      },
      openLink(link) {
        window.open(link, '_blank')
      }
    }
  }
</script>
<style scoped>
.map{height: 300px;}
.h5_header{background-image: linear-gradient(90deg, #009ef6 0%, #5060d6 100%);width:100%;text-align: center;overflow: hidden;color:#fff;padding:10px 0;}
.h5_header h1{font-size:16px;line-height:40px;color:#fff;font-weight: 600;}
.h5_header p{line-height:20px;font-size:12px;color:#fff;}
.h5_nav{margin:15px 15px 0;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.h5_nav a{height:30px;line-height:30px;background:#e5e5e5;font-size:12px;border-radius:15px;color:rgba(0,0,0,.5);display: inline-block;padding:0 15px;cursor: pointer;font-weight: 600;}
.h5_nav a.active{color:#fff;background-image: linear-gradient(90deg, #009ef6 0%, #5060d6 100%);}
.gs_cont,.jczl_cont,.zdxxzy_cont{padding:15px;}
.h5_card{background-color: #ffffff;box-shadow:0px 2px 8px 0px rgba(194,194,194,0.2);border-radius:3px;padding: 15px;}
.h5_card h3,.h5_card p{font-size:12px;line-height:24px;color:rgba(0,0,0,.8);}
.h5_card h3,.tszl_cont dt{font-weight:600;}
.h5_card p{text-indent: 2em;color:rgba(51,51,51,.8);}
.h5_card .tit {border-left:2px solid #555DFE;padding-left:15px;font-weight:600;font-size:14px;line-height:14px;color:#000;}
.h5_card .tit .cor_f00{color:red;}
.h5_card .nav-pills{margin:0.21rem auto 0;width:auto;float: none;text-align: center;}
.h5_card .nav-pills li{margin:0.21rem 0.08rem 0;display: inline-block;float: none;}
.h5_card .nav-pills li a{font-size:1rem;background:#e9e9e9;color:rgba(0,0,0,.47);padding: 0.25rem 1.125rem;border-radius:0.625rem;}
.h5_card .nav-pills > li.active > a,.h5_card  .nav-pills > li.active > a:hover,.h5_card .nav-pills > li.active > a:focus{color:#fff;background:#2a67b3;}
.mtly h5{font-size:14px;line-height: 30px;}
.mtly li{line-height:30px;font-size:12px;overflow: hidden;}
.mtly .num_tit{width:50px;float:left;}
.mtly .num{float: left;width:16px;height:16px;background:#d82008;margin:8px 34px 0 0;line-height:16px;color:#fff;text-align: center;font-size:12px;}
.mtly .bg_ffb{background: #ffb229;}
.mtly .bg_ec7{background: #ec753a;}
.mtly .bg_2a6{background:#2a67b3;}
.tab-content li a{float:left;width:15%;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;font-size:12px;text-align: left;}
.mtly .redu{width:40px;text-align: center;float:right;font-size:12px;}
.progressBar{float: left;width:43%;margin-top:10px;}
.progressBar .barline{float: left;width: 100%;background: rgb(223, 223, 223);height:10px;overflow: hidden;position: relative;border-radius:5px;}
.progressBar .charts{width: 70%;background-image: linear-gradient(90deg,#555DFE 0%,#5660d2 100%),linear-gradient(#ffffff,#ffffff);background-blend-mode: normal,normal;height:10px;border-radius:5px;}
.top_mtly .nav-pills > li.active > a,.top_mtly .nav-pills > li.active > a:hover,.top_mtly .nav-pills > li.active > a:focus{color:#fff;background:#f00;}
.top_mtly .charts{background-image: linear-gradient(90deg, #dd897a 0%, #d33e5e 44%, #e11f37 83%, #ee000f 100%), linear-gradient(#96ddfe, #96ddfe);width:90%;}
/*重点信息摘要*/
.zdxxzy_cont p.zyxx{margin:0 15px;font-size:12px;line-height:30px;color:rgb(0,0,0);}
.zdxxzy_cont h4{font-size:12px;line-height:24px;color:rgb(0,0,0);font-weight:600;max-height:120px;display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:2;}
.zdxxzy_cont h4 .mg,.zdxxzy_cont h4 .fmg{background: #f00;border-radius:5px;font-size:12px;padding:5px 10px;color: #fff;margin:0 5px;line-height:22px;float: none;}
.zdxxzy_cont h4 .fmg{background:#555DFE;}
.zdxxzy_cont .cor_006{color:#0068b7;}
.zdxxzy_cont .fbsj{line-height:30px;color:rgba(0,0,0,.8);font-size:12px;text-indent:0;}
.zdxxzy_cont .fbsj span{padding-right:10px;}
.zdxxzy_cont .summary{background:rgba(191,191,191,.1);padding:15px;font-size:12px;line-height:18px;color:rgba(0,0,0,.6);text-indent:0;}
.zdxxzy_cont a{text-align: center;font-size:12px;line-height:30px;color:#000;width: 100%;display: block;}
.zdxxzy_cont .xh{font-size:12px;line-height:30px;padding: 10px 0 0;}
.h5_footer{text-align: center;font-size:12px;line-height:60px;color:rgba(0,0,0,.26);}
.w-500{width: 100%;}
@media screen and (min-width:500px) {
  .w-500{width:500px;margin:0 auto;}
}
.button_group >>> .el-loading-mask{border-radius: 25px;}
.echarts_empty{height: 300px;}
.fz1rem {font-size: 1rem;}
.hr-10 {height: 10px;overflow: hidden;}
ul.zdxxzy_cont li:not(:last-child) {border-bottom: 1px solid rgba(0, 0, 0, .1);padding: 10px 0;}
ul.zdxxzy_cont li:last-child {padding: 10px 0 0;}
.hot_keywords {width: 100%;}
.hot_keywords table, .hot_keywords th, .hot_keywords td, .hot_keywords tr {border-collapse: collapse;line-height:30px;font-size:12px;text-align: center;color: #010101;}
.hot_keywords td {border: 1px #fff solid;}
.hot_keywords th {font-size:14px;background: #3386C5;color: #fff;font-weight:400;}
.hot_keywords tr:nth-child(2n) {background: #FBFBFB;}
.hot_keywords tr:nth-child(2n+1) {background: #E4EFFE;}
.hot_keywords tr:nth-child(2) td, .hot_keywords tr:nth-child(3) td, .hot_keywords tr:nth-child(4) td {color: #0068B7;}
.mg_hot_keywords .red, .mg_hot_keywords tr:nth-child(2) td, .mg_hot_keywords tr:nth-child(3) td, .mg_hot_keywords tr:nth-child(4) td, .red {color: #f00;}
a.more{text-align: center;font-size:12px;line-height:50px;color:#000;width: 100%;display: block;font-weight: 600;}
</style>